<template>
  <div class="vip-pay app_content">
    <div class="warp_body">
      <div>
        <div class="vipList">
          <van-radio-group v-model="checked" v-for="item in  vipList" v-bind:key="item">
            <van-radio :name="item.code" checked-color="#ee0a24" @click="chooseVip(item)">{{ item.name }}</van-radio>
          </van-radio-group>
        </div>

        <div v-show="checked" class="vipDetail">
          <div class="vipDetailPrice"><span>价格：</span><span>{{ (parseFloat(vip.price) / 100).toFixed(2) + '元' }}</span>
          </div>
          <div class="vipDetailDesc"><span>说明：</span><span v-html="vip.rule"></span></div>

          <div style="text-align: center;margin-top: 40px ">
            <van-button round plain block type="primary" :disabled="payButtonDisable" @click="pay">
              确认支付
            </van-button>
          </div>


        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API from "../api/Pay";
import req from "../request/request";
import router from "../router";
import {prePay} from "../util/wechatPay";


export default {
  name: 'VipPay',

  setup() {
    onBeforeMount(() => {
      methodsMap.geVipConfList()
    });
    const data = reactive({
          vipList: [],
          checked: '',
          vip: '',
          payButtonDisable: false,
        },
    )

    const methodsMap = {
      geVipConfList: () => {
        req.request(API.getVipList, null, {method: 'GET'}).then((res) => {
          data.vipList = res.data.data
        })
      },
      chooseVip: (val) => {
        console.log(val)
        data.vip = val
      },
      pay: () => {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', methodsMap.onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', methodsMap.onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', methodsMap.onBridgeReady);
          }
        } else {
          data.payButtonDisable = true;
          req.request(API.createOrder, {
            vipCode: data.vip.code,
            openid: localStorage.getItem("openid")
          }, {method: 'POST'}).then((res) => {
            let getBrandWCPayRequest = res.data.data
            prePay(getBrandWCPayRequest)
          })

        }
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

    .vipList {
      margin-top: 20px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;

    }

    .vipDetail {
      margin: 20px 40px;

      > div {
        padding-top: 20px;
      }
    }

  }
}
</style>
