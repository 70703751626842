import {Toast} from "vant";

export function prePay (params) {
    if (typeof window.WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', function () { onBridgeReady(params) }, false)
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', function () { onBridgeReady(params) })
            document.attachEvent('onWeixinJSBridgeReady', function () { onBridgeReady(params) })
        }
    } else {
        onBridgeReady(params)
    }
}

function onBridgeReady (params) {
    window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
            'appId': params.appId, // 公众号名称，由商户传入
            'timeStamp': params.timeStamp, // 时间戳，自1970年以来的秒数
            'nonceStr': params.nonceStr, // 随机串
            'package': params.packageValue,
            'signType': params.signType, // 微信签名方式：
            'paySign': params.paySign // 微信签名
        },
        function (res) {
            // location.href = params.Url
            if (res.err_msg == "get_brand_wcpay_request:ok") {

                Toast.success("支付成功")
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            }
        }

    )
}